const FantasyData = [
    {
        id: 'panel1',
        heading: 'Why Fantasy Games?',
        content1: "Well, when it comes to fantasy games, we all are big fans. As a true fan, fantasy cricket and football or any fantasy gaming is something that you would absolutely love. It not only lets you use your knowledge for the game, but also improves your skills and strategy to put together to create your own team of 11 players for this fantasy sport. Of course, as you put the fantasy team together, you also win big cash prizes as the fantasy cricket game unfolds in its true glory.",
        content2: "Fantasy Cricket or fantasy football matches are convenient to get started with. Just register and start playing from wherever you are. Challenges are intense, and the excitement just can't stop at one. What makes it so irresistible? The smart way to enjoy something you are passionate about and winning cash by being a pro at the fantasy cricket matches.",
        content3: "You can start playing fantasy games right away - Register with us and pick a fantasy league that is open. Every fantasy match you pick will let you play on multiple factors. Once you have the basics sorted, you are all set to challenge your opponents. You score points as the gameplay moves as you expected it to. To come out as the winner, your knowledge for fantasy cricket or any game will be the most important thing.",
        content4: 'Excited to get started? Download BigCricket11.com app now! Play fantasy games such as fantasy cricket or fantasy football and win cash daily, as you show your knowledge and skills for the game. You can also invite your friends and enjoy an exclusive referral bonus as well. This is going to be the season of unparalleled excitement, thrill and intense fantasy gameplay.'

    },
    {
        id: 'panel2',
        heading: 'Unique Features of BigCricket11',
        content1: "BigCricket11 fantasy cricket app gives you a unique platform to challenge the best of the best in cricket. And in doing so, win money! Let’s look at a few perks of playing fantasy cricket on BigCricket11:",
        content: '',
        content: ''
    },
    {
        id: 'panel3',
        heading: 'Playing Fantasy Cricket & Football is Safe, Secure & Legal',
        content1: 'Get the maximum out of your gaming experience and that too in the most secure and safe way. Fantasy cricket and fantasy football are absolutely legal to be played and enjoyed in India. Whether you select the free fantasy cricket games or football games you want to play and win cash prizes, you can do both with complete confidence and peace of mind. Our payment gateway is completely secure, and the winning amount is transferred to your account. Fantasy games are skill based games and do not fall under gambling and are 100% legal in India.',
        content2: "BigCricket11.com is a part of Play Games24x7 that runs India's largest rummy website; RummyCircle with 10+ Million players and multiple gaming apps under the Ultimate Games banner. Players can come and enjoy their dream fantasy cricket or fantasy football game, with a smooth, fast and secure platform. Download our app or simply register with us and start playing your favorite fantasy sport games."
    }
];
export default FantasyData