import { Box, List, ListItemText } from "@mui/material"
import { AiFillInstagram } from 'react-icons/ai';
import { NavLink } from "react-router-dom";
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import './footerStyle.css'
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import imglogo1 from '../../Assets/18plus-icon-v1.png'
import imglogo2 from '../../Assets/responsible-play-v1.png'
import cashfree from '../../Assets/cashfree.png'
import razorpay from '../../Assets/razorpay-icon.png'
import paytm from '../../Assets/paytm-icon.png'
import appdebug from '../../Assets/app-debug.apk'


const Footer = () => {
    const handleDownload = () => {
        // Replace 'your_apk_file_name.apk' with the actual name of your APK file
        const apkFileName = appdebug;
        const apkUrl = process.env.PUBLIC_URL + '/' + apkFileName;
        window.open(apkUrl, '_blank');
    };
    return (
        <>
            <Box className="footerMain">
                <Box>
                    <List className="footer-list">
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Home</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> How To Play</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Legality</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> How To Download</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Points System</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Tips & Tricks</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Contact Us</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Terms of Service</ListItemText>
                    </List>
                </Box>
                <Box>
                    <List className="footer-list">
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Privacy Policy</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight />  Promotions</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Withdraw Cash</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> T20 World Cup</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Help</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Brand Asset Center</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Fantasy Cricket League</ListItemText>

                    </List>
                </Box>
                <Box>
                    <List className="footer-list">
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Cricket Records</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> About Us</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Blog</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Cricket Schedule</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> TATA IPL 2024</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Hacking Awareness</ListItemText>
                        <ListItemText className="footer-listItem"><MdKeyboardDoubleArrowRight /> Today's Match Prediction</ListItemText>
                    </List>
                </Box>
                <Box>
                    <List className="footer-list footer-listLast">
                        <div>
                            <p className="fs12">Security & Game Integrity</p>
                            <div className=" d-flex mb-4">
                                <img className="mx-1" src={imglogo1} alt="img" />
                                <img className="mx-1" src={imglogo2} alt="img" />
                            </div>
                        </div>

                        <div>
                            <p className="fs12">Our Payment Partners</p>
                            <div className=" d-flex">
                                <div className="px-1 py-2 m-1 bg-white rounded-1">
                                    <img className="img" src={cashfree} alt="img" />
                                </div>
                                <div className="px-1 py-2 m-1 bg-white rounded-1">
                                    <img className="img" src={razorpay} alt="img" />
                                </div>
                                <div className="px-1 py-2 m-1 bg-white rounded-1">
                                    <img className="img" src={paytm} alt="img" />
                                </div>
                            </div>
                        </div>

                    </List>
                </Box>
            </Box>

            <Box className="socialDiv">
                <Box>
                    <TwitterIcon className="socialIcon" />
                    <AiFillInstagram className="socialIcon fs-4" />
                    <FacebookIcon className="socialIcon" />
                    <LinkedInIcon className="socialIcon" />
                    <YouTubeIcon className="socialIcon" />
                    <EmailIcon className="socialIcon" />
                </Box>
                <Box className="downloadIcon">
                    <img onClick={handleDownload} src="https://s.alicdn.com/@img/imgextra/i4/O1CN018KnDNq1JleFgkjLRq_!!6000000001069-2-tps-447-132.png" alt="img" />
                    <img src="https://s.alicdn.com/@img/imgextra/i4/O1CN01i9Aj641atkjJJ9I6y_!!6000000003388-2-tps-396-132.png" alt="img" />
                </Box>
            </Box>

            <Box className="cpyright">
                <p>Copyright © 2024 Play Games24x7 Pvt. Ltd. All Rights Reserved.</p>
            </Box>

        </>
    )
}

export default Footer

