import { useNavigate } from 'react-router-dom'
import '../Styles/notFoundStyle.css'

const NotFound = () => {
    const navigate = useNavigate();

    const onBackbtn = () => {
        navigate('/')
    }
    return (
        <>
            <div class="not-found-container">
                <h1>Oops!</h1>
                <h4>Something went wrong!</h4>
                <button onClick={onBackbtn} type="button" class="btn btn-dark">
                    GO TO HOME
                </button>
            </div>

        </>
    )
}

export default NotFound
