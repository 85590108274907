import { Box, Button, InputAdornment, TextField } from "@mui/material"
import stadiumImg from '../Assets/stadium.png'
import '../Styles/loginStyle.css'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import { MdMail } from "react-icons/md";
import { Link } from "react-router-dom";

const Login = () => {
    return (
        <>
            <Box className="loginDiv">
                <div className="login-leftDiv">
                    <div className="pdiv">
                        <p>Welcome to</p>
                        <h3>Big Cricket 11</h3>
                    </div>

                    <p className=" text-center my-5 text-uppercase fw-bold">Login</p>
                    <div className="emaillogin">
                        <div className="inputbox">
                            <TextField
                                // label="Email ID / Username"
                                placeholder="Email ID / Username"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdMail className=" fs-3 pe-2" style={{ color: 'grey', borderRight: '1px solid grey' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                fullWidth
                            // sx={{ width: '545px' }}
                            />
                        </div>
                        <div className="btnbox">
                            <Button variant="contained" fullWidth>Continue</Button>
                        </div>
                        <div className="polcydiv">
                            <p>By logging in, you accept you are 18+ and agree to our <Link> T&C </Link> & <Link> Privacy Policy </Link></p>
                        </div>
                        <div className="mobilediv">
                            <p>Login/Register using - <Link to={'/register'}>Mobile Number</Link></p>
                        </div>
                        <Box className="polcydiv">
                            <p style={{ fontSize: '12px', paddingTop: '55px' }}>Copyright © 2024 Play Games24x7 Pvt. Ltd. All Rights Reserved.</p>
                        </Box>
                    </div>
                </div>
                <div className="login-imgdiv">
                    <img src={stadiumImg} alt="img" />
                </div>
            </Box>

        </>
    )
}

export default Login
