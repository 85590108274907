import './headerStyle.css'
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import imgProfile from '../../Assets/profile.png'
import { Box, Button } from '@mui/material';
import { MdOutlineAccountCircle, MdOutlineSettings } from "react-icons/md";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {

  const [open, setOpen] = useState(false);

  const onProfileToggleOpen = () => {
    setOpen(!open)
  }
  return (
    <>
      <div className="headerbox">
        <div className='heading-div'>
          <p>Big Cricket 11</p>
        </div>
        <div className='profilebox' >
          <div>
            <p className='notmemb'>Not a Member Yet?</p>
          </div>
          <div>
            <p><Link to={'/register'}> <Button size='small'>Register Now</Button></Link></p>
          </div>
          <div>
            <Link to={'/login'}> <Button size='small'>Log In</Button></Link>
          </div>

        </div>
      </div>

    </>
  )
}

export default Header
