import Header from "../Common/Header/Header"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import br1 from '../Assets/bg11.jpg'
import br2 from '../Assets/bg22.jpg'
import '../Styles/homeStyle.css'
import { Box, Button } from "@mui/material";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaSackDollar } from "react-icons/fa6";
import { GiStarsStack } from "react-icons/gi";
import img1 from '../Assets/img1.png'
import img2 from '../Assets/img2.png'
import img3 from '../Assets/img3.png'
import fantasyImg from '../Assets/fantasy.png'
import Footer from "../Common/Footer/Footer";
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FreqAskQuesData from "../data/FreqAskQuesData";
import FootballData from "../data/FootballData";
import { MdAdd } from "react-icons/md";
import FantasyData from "../data/FantasyData";


const HomePage = () => {
    const [value, setValue] = useState(0);

    const onHandleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Header />

            <div>
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}

                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    modules={[Pagination, Autoplay]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className="item">
                            <img
                                className="img-itm"
                                src={br1}
                                alt="imgimf"
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="item">
                            <img
                                className="img-itm"
                                src={br2}
                                alt="imgimf"
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="item">
                            <img
                                className="img-itm"
                                src={br1}
                                alt="imgimf"
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="item">
                            <img
                                className="img-itm"
                                src={br2}
                                alt="imgimf"
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>


            </div>

            <Box className="mainDiv">
                <div className="infoDiv">
                    <div className="iconDiv">
                        <GiStarsStack className="icon" />
                    </div>
                    <div className="infoTextDiv">
                        <p>4.3 OUT OF 5</p>
                        <h4>USER RATING</h4>
                    </div>
                </div>
                <div className="infoDiv">
                    <div className="iconDiv">
                        <PiUsersThreeFill className="icon" />
                    </div>
                    <div className="infoTextDiv">
                        <p>4+ CRORE</p>
                        <h4>TOTAL USERS</h4>
                    </div>
                </div>
                <div className="infoDiv">
                    <div className="iconDiv">
                        <FaSackDollar className="icon" />
                    </div>
                    <div className="infoTextDiv">
                        <p>₹ 500 CRORE+</p>
                        <h4>PRIZES WON</h4>
                    </div>
                </div>

            </Box>
            <div className="steps">
                <h3 className=" text-center fw-bold pt-3 text-uppercase">3 Easy Steps</h3>
                <Box className="stepmainDiv">
                    <div className="stepDiv">
                        <div className="imgDiv">
                            <img src={img1} alt="img" />
                        </div>
                        <div className="stepTextDiv">
                            <h5>Select a Match</h5>
                            <p>Select an upcoming match of your choice</p>
                        </div>
                    </div>
                    <div className="stepDiv">
                        <div className="imgDiv">
                            <img src={img2} alt="img" />
                        </div>
                        <div className="stepTextDiv">
                            <h5>Create your own team</h5>
                            <p>Use your sports knowledge and check player stats on the platform to create a team using 100 credits</p>
                        </div>
                    </div>
                    <div className="stepDiv">
                        <div className="imgDiv">
                            <img src={img3} alt="img" />
                        </div>
                        <div className="stepTextDiv">
                            <h5>Join Free & Cash Contests</h5>
                            <p>Participate in Cash or Practice Contests.</p>
                        </div>
                    </div>

                </Box>
            </div>

            <Box className="fantasy">
                <div className="textfantasy">
                    <h4>Play Fantasy Cricket on BigCricket11 App</h4>
                    <p>Want to enjoy fantasy games like cricket but just can't manage the time? Well, BigCricket11.com is the answer you need. This is the place where your favorite fantasy sports come alive. Enjoy playing fantasy cricket, fantasy Football, and fantasy kabaddi right on your device. Pick teams of your choice and play the game.</p>
                    <p>BigCricket11.com, a part of Games24x7, brings the best fantasy games at your fingertips. It is committed to offering the same gameplay experience as RummyCircle, India's largest rummy platform with 10+ Million players. Register with us, pick a game and win cash daily. Don't wait further. Join us now and enjoy the fantasy games.</p>
                    <p>Fantasy cricket and football or any fantasy games in general boost your skill and let you win real cash rewards. We offer a safe and secured platform to enjoy online fantasy sports at your leisure. Get started with the game right away and join India's fastest growing online fantasy cricket app and experience the real action and thrill.</p>
                    <Button>Today’s Match Preview</Button>
                </div>
                <div>
                    <img src={fantasyImg} alt="img" />
                </div>
            </Box>

            {/* <Box>
                <Tabs value={value} onChange={onHandleChange}>
                    <TabsList className="tabslist-div">
                        <Tab className="mange-tab" style={value == 0 ? { borderBottom: '2px solid brown', color: 'brown', fontWeight: 'bold' } : {}} value={0}>CRICKET </Tab>
                        <Tab className="mange-tab" style={value == 1 ? { borderBottom: '2px solid brown', color: 'brown', fontWeight: 'bold' } : {}} value={1}>FOOTBALL </Tab>
                    </TabsList>

                    <TabPanel value={0}>
                        <div className="accord-ques-div">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className="accord-ques">
                                        What is Fantasy Cricket?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        cvfv
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className="accord-ques">How to Download the Fantasy Cricket App?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        cfffff
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className="accord-ques">
                                        Play Daily Fantasy Cricket Tournaments & Win Real Cash on BigCricket11
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        ddsfsgfd
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography className="accord-ques">Benefits of Playing Fantasy Sports on BigCricket11 App</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        Nunce.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5bh-content"
                                    id="panel5bh-header"
                                >
                                    <Typography className="accord-ques">Can I Play Practice Fantasy Cricket Games on BigCricket11?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        Nunce.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel6bh-content"
                                    id="panel6bh-header"
                                >
                                    <Typography className="accord-ques">What Exactly Is Indian T20 League? How Do I Sign Up For it?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        Nunce.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel7bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography className="accord-ques">Any Quick Tips On Making The Best Fantasy Cricket Team?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        Nunce.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel8bh-content"
                                    id="panel4bh-header"
                                >
                                    <Typography className="accord-ques">How to play fantasy cricket?</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        Nunce.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel9bh-content"
                                    id="panel9bh-header"
                                >
                                    <Typography className="accord-ques">Fantasy Cricket Winning techniques</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        Nunce.
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </TabPanel>
                    <TabPanel value={1}>
                        <div className="accord-ques-div">
                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography className="accord-ques">
                                        What is Fantasy Football?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        cvfv
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                >
                                    <Typography className="accord-ques">Daily Fantasy Football on BigCricket11</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        cfffff
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className="accord-ques">
                                        What will you get when you download BigCricket11 app for fantasy football?
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography className="accord-answr">
                                        ddsfsgfd
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                        </div>
                    </TabPanel>
                </Tabs>
            </Box>

            <div className="bigquesdiv">
                {FantasyData?.map((item) => (
                    <Accordion
                        key={item.id}
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                        className='accordion-bigdiv'
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                        >
                            <Typography className="bigdiv-ques">
                                {item.heading}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className="bigdiv-answr">{item.content1}</Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography className="bigdiv-answr">{item.content2} </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography className="bigdiv-answr">{item.content3}</Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Typography className="bigdiv-answr">{item.content4}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div> */}

            <Footer />

        </>
    )
}

export default HomePage

